<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <v-app>
      <!-- <v-app-bar app dense dark>
      <v-toolbar-title>Wapidu</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'login' }">
        Iniciar Sesión
      </v-btn>

      <v-btn color="primary" :to="{ name: 'signup' }">
        Pruébalo
      </v-btn>
    </v-app-bar> -->

      <v-main>
        <div class="container-fluid img-bg d-flex">
          <div class="container">
            <div class="row m-0 pb-5 pt-5 align-items-center">
              <div class="col-12 col-md-6">
                <h1 class="title-landin wow fadeInUp">
                  ¿Por qué pasar tu fin de semana lavando ropa? <br />
                </h1>
                <h3 class="subtitle-landin wow fadeInUp">
                  Recogemos tu ropa y te la devolvemos lavada, planchada o de tintorería.
                </h3>
              </div>
              <div class="col-12 col-md-6">
                <div class="card card-form" style="width: 100%">
                  <div class="card-body">
                    <h3 class="card-title">
                      Déjanos tus datos y recibe todas nuestras promociones
                    </h3>
                    <form class="bg-blanco">
                      <div class="mb-3">
                        <label for="validationCustom01" class="form-label">Nombre</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom01"
                          placeholder="Ej: Juan"
                          required
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label color-gris-wapidu"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Ej: mi.nombre@mail.com"
                        />
                        <div id="emailHelp" class="form-text color-gris-wapidu">
                          Nunca compartiremos tu correo electrónico con nadie más.
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="validationCustom01" class="form-label">Teléfono</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom01"
                          placeholder="Ej: (55) 5555 5555"
                          required
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="home-legal-check" />
                        <label class="form-check-label" for="home-legal-check"
                          ><small
                            >He leído el <a href="/" target="_blank">Aviso de Privacidad</a> y estoy
                            de acuerdo con los
                            <a href="/" target="_blank">Términos y Condiciones</a></small
                          ></label
                        >
                      </div>
                      <button type="submit" class="btn primary text-white">Enviar</button>
                    </form>
                  </div>
                </div>

                <!-- <img
                  :src="require('@/assets/img/img-inicio/wapiduBg.png')"
                  class="imgPagInicio"
                  alt=""
                />
                <v-btn
                  class="btn-bg mt-3 primary d-flex justify-content-center"
                  :to="{ name: 'productos' }"
                  x-large
                >
                  Ve nuestros servicios
                </v-btn> -->
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl espacios">
          <div class="row">
            <div class="col-12 col-sm-9 mx-auto">
              <h3 class="subtitulo-landing text-center mt-5 wow fadeInUp">
                SUSCRIPCIÓN DE LAVANDERÍA
              </h3>
              <h4 class="text-center mt-5 texto-landing wow fadeInUp">
                Deja que los profesionales: Separen, laven y doblen tus prendas por una tarifa plana
                al mes,<br /><strong>4 visitas mensuales</strong>
              </h4>
            </div>
            <div class="col-12">
              <SeccionProductos />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center mt-3">
              <h4 class="text-center mt-5 texto-landing">
                Suscríbete para gozar <strong> lo mejor de la lavandería</strong>
              </h4>
              <v-btn class="btn-bg mt-3" color="primary" :to="{ name: 'servicios' }" x-large>
                Ver todos los servicios
              </v-btn>
            </div>
          </div>
        </div>
        <!-- div class="container-xxl espacios">
          <div class="row">
            <div class="col-12">
              <h3 class="subtitulo-landing text-center mt-5 wow fadeInUp">
                SUSCRIPCIÓN DE TINTORERÍA
              </h3>
              <h4 class="text-center mt-5 texto-landing wow fadeInUp">
                Deja que los profesionales desmanchen, laven, planchen y/o doblen tus prendas por
                una tarifa plana al mes, <strong>4 visitas mensuales</strong>
              </h4>
            </div>
          </div>
          <div class="row m-5 wow fadeInUp">
            <div class="col-12 col-md-4">
              <img
                v-bind:src="require('../../assets/img/img-inicio/small+wapibag.png')"
                class="img-fluid"
                alt="suscripcion big-family"
              />
              <p class="text-center text-wrapper">
                Suscripción para 1-2 personas <strong>$1900.- </strong>
              </p>
              <p class="text-center text-wrapper-2 mt-3">
                pago mensual con cargo recurrente,<br />
                4 visitas al mes
              </p>
            </div>
            <div class="col-12 col-md-4">
              <img
                v-bind:src="require('../../assets/img/img-inicio/medium+wapibag.png')"
                class="img-fluid"
                alt="suscripcion big-family"
              />
              <p class="text-center text-wrapper">
                Suscripción para 3-4 personas <strong>$3000.-</strong>
              </p>
              <p class="text-center text-wrapper-2 mt-3">
                pago mensual con cargo recurrente,<br />
                4 visitas al mes
              </p>
            </div>
            <div class="col-12 col-md-4">
              <img
                v-bind:src="require('../../assets/img/img-inicio/image-asset.png')"
                class="img-fluid"
                alt="suscripcion big-family"
              />
              <p class="text-center text-wrapper">
                Suscripción para 5-6 personas <strong>$4500.-</strong>
              </p>
              <p class="text-center text-wrapper-2 mt-3">
                pago mensual con cargo recurrente,<br />
                4 visitas al mes
              </p>
            </div>
            <div class="col-12 text-center">
              <v-btn class="btn-bg mt-3" color="primary" :to="{ name: 'signup' }" x-large>
                Suscríbete
              </v-btn>
            </div>
          </div>
        </div>
        <hr />
        <div class="container-xxl espacios wow fadeInUp">
          <div class="row">
            <div class="col-12">
              <h3 class="subtitulo-landing text-center mt-5">
                ¡TODAS LAS PRENDAS QUE PUEDAS METER EN LA WAPIBAG!
              </h3>
              <h4 class="text-center mt-5 text-wrapper">
                Camisa, pantalón, saco, falda, jeans, playera, corbata, sudadera, etc...<br />
                (Desmanchado, Lavado, Planchado y/o Doblado)
              </h4>
            </div>
            <div class="col-12 text-center">
              <v-btn class="btn-bg mt-3" color="primary" :to="{ name: 'signup' }" x-large>
                Suscríbete
              </v-btn>
            </div>
          </div>
        </div-->
        <div
          :style="{
            'background-image': 'url(' + require('../../assets/img/img-inicio/fondo-1.jpg') + ')',
          }"
          class="container-fluid img-bg-2 d-flex espacios"
        >
          <div class="row justify-content-center align-items-center">
            <div class="col-12 justify-content-center">
              <h3 class="texto-bg-color title-bg-color text-center">
                Wapidu llegó para que le digas adiós a la lavadora en casa.
              </h3>
              <p class="texto-bg-color parrafo-bg-color justify-content-center">
                Con nosotros recibirás un servicio de lavandería y tintorería rápido e innovador,
                acompañado de los mejores aliados. Solo tienes que programar desde la aplicación el
                horario que más te convenga para la recolecta y entrega de tu ropa y... ¡listo!.
              </p>
            </div>
          </div>
        </div>
        <div class="container-xxl d-flex espacios">
          <div class="row align-items-center">
            <div class="col-12 col-md-6 mt-5">
              <img
                v-bind:src="require('../../assets/img/img-inicio/fondo-2.jpg')"
                class="img-fluid"
                alt="wapidu persona haciendo yoga"
              />
            </div>
            <div class="col-12 col-md-6 mt-5 color-gris-wapidu">
              <h4 class="">Ya no está de moda lavar ropa en casa.</h4>
              <h5>
                Deja que Wapidu lo haga por ti y dedica tu valioso tiempo en las cosas que más amas
              </h5>
              <p>
                Tú dices cuándo y a qué hora recogemos tu ropa y nosotros te la regresamos limpia,
                planchada y oliendo a casa
              </p>
              <v-btn color="primary" :to="{ name: 'signup' }" class="btn-bg mt-3" x-large>
                Registrate
              </v-btn>
            </div>
          </div>
        </div>
        <hr />
        <v-container class="wow fadeInUp">
          <v-row justify="center">
            <v-col cols="12" lg="8">
              <v-img src="@/assets/img/logo_color.png" />
            </v-col>

            <v-col cols="12" class="text-center">
              <v-btn color="primary" :to="{ name: 'signup' }" x-large> Pruébalo </v-btn>
            </v-col>
          </v-row>

          <v-row class="primary lighten-3 my-8 py-10">
            <v-col cols="12">
              <h2 class="text-center white--text">Servicios</h2>
            </v-col>

            <v-col cols="12" md="4">
              <v-card>
                <v-card-title>Lavandería</v-card-title>
                <v-card-text>
                  Deja que los profesionales hagan por ti el trabajo de lavado y doblado de tus
                  prendas textiles.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card>
                <v-card-title>Planchado</v-card-title>
                <v-card-text>
                  Quitar arrugas y marcas de las prendas ya que gracias al calor utilizado en el
                  proceso hará que las fibras no se estiren y recuperen su forma inicial (u
                  original) dejándolas listas para lucir.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card>
                <v-card-title>Tintorería</v-card-title>
                <v-card-text>
                  Deja que los profesionales se encarguen del desmanchado, lavado, secado, planchado
                  o doblado de todas tus textiles, total atención a nuestros clientes y a sus
                  prendas textiles.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <v-footer app> Wapidu&copy; {{ new Date().getFullYear() }} </v-footer>
    </v-app>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/index.vue';
import SeccionProductos from '@/views/landing/components/SeccionProductos.vue';

export default {
  name: 'Landing',
  components: {
    Navbar,
    SeccionProductos,
  },
  data: () => ({}),
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.color-gris-wapidu {
  color: #4a516d;
}

.btn-bg {
  background-color: #0087a7 !important;
}
.primary {
  background-color: #0283a4 !important;
}
.imgPagInicio {
  width: 90%;
  height: auto;
}
.parrafo-bg-color {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: calc((1.4 - 1) * 1.2vh + 1rem);
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  text-align: justify !important;
}

.texto-bg-color {
  color: #fff;
}
.title-bg-color {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: calc((2.2 - 1) * 1.2vh + 1rem);
}

.text-wrapper {
  font-size: calc(1.5 * 1rem);
  color: #4a516d;
}

.text-wrapper-2 {
  font-size: calc(1.2 * 1rem);
  color: #4a516d;
}

.texto-landing {
  font-size: calc((2.2 - 1) * 1.2vw + 1rem);
  color: #4a516d;
  font-weight: 400;
  z-index: 100;
}

.subtitulo-landing {
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  color: #4a516d;
  font-weight: 700;
}

.img-bg {
  background: linear-gradient(rgba(74, 81, 109, 0.3), rgba(74, 81, 109, 0.5)),
    url('../../assets/img/img-inicio/lavadoraoscura.jpg') no-repeat center bottom fixed;
  background-size: cover;
  height: auto;
  min-height: 70vh;
  width: 100%;
}
.img-bg .row {
  height: 100%;
}
// .img-bg:before {
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
// }
.card-form {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.img-bg-2 {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  height: 60vh !important;
  width: 100%;
}

.espacios {
  margin-top: 100px;
  margin-bottom: 100px;
}

.title-landin {
  max-width: 1200px;
  font-size: calc((3 - 1) * 1.2vw + 1rem);
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.subtitle-landin {
  margin-top: 25px;
  font-size: calc((2 - 1) * 1.2vw + 1rem);
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  color: #ffffff;
}
.card-title {
  font-size: calc((1.3 - 1) * 1.2vw + 1rem);
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #4a516d;
}
</style>
