<template>
  <div>
    <nav class="navbar navbar-expand-lg" style="background-color: #3f51b5">
      <div class="container-fluid ms-3 me-3">
        <button
          class="navbar-toggler collapse-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" href="#"
          ><img src="@/assets/img/logo_white.png" class="img-logo-menu" alt="logo blanco wapidu"
        /></a>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav me-5 ms-4 mb-2 mb-lg-0" v-for="item in menuItems" :key="item.key">
            <li class="nav-item" v-if="item.name">
              <router-link :to="item.name" class="nav-link">{{ item.label }}</router-link>
            </li>
            <li class="nav-item" v-else-if="item.path">
              <a :href="item.path" class="nav-link">{{ item.label }}</a>
            </li>
          </ul>
        </div>
        <div class="d-flex justify-content-end me-5">
          <div class="d-flex d-none d-md-flex flex-row align-items-center">
            <button
              class="btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              v-if="cart.length > 0"
            >
              <span class="shop-bag"><i class="mdi mdi-shopping"></i></span>
            </button>
            <button class="btn" type="button" v-else>
              <span class="shop-bag"><i class="mdi mdi-shopping"></i></span>
            </button>
            <div class="d-flex flex-column ms-2">
              <span class="qty" v-if="cart.length > 0">Servicios {{ cart.length }}</span>
              <span class="qty" v-else>Sin Productos</span>
              <span class="fw-bold" v-if="cartTotal > 0">$ {{ cartTotal }}</span>
              <span class="fw-bold d-none" v-else>$ 0</span>
            </div>
            <div class="d-flex flex-column ms-2" v-if="isLogged">
              <button type="button" class="block float-end nav-link" @click="fullLogOut">
                <v-icon>mdi-logout-variant</v-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Seccion sildebar car -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <!-- Seccion sildebar car -->
      <!-- eslint-disable max-len -->
      <div class="offcanvas-body">
        <button
          type="button"
          class="btn-close block float-end"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <CardCarrito></CardCarrito>
        <div class="col-12" v-if="cart.length !== 0">
          <div class="d-grid gap-2">
            <router-link
              class="btn btn-success boton-verde"
              to="/cart"
              type="button"
              data-bs-dismiss="offcanvas"
            >
              Ir al Carrito
            </router-link>
          </div>
        </div>
        <div class="col-12" v-else>
          <div class="d-grid gap-2"></div>
        </div>
      </div>
      <!-- eslint-enable max-len -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import CardCarrito from '@/components/tienda/carrito/index.vue';

export default {
  name: 'navbar',
  data() {
    const isLogged = false;
    const menuItems = [
      {
        label: 'Inicio',
        path: '/#/',
        key: 'home',
        // name: 'landing',
      },
      {
        label: 'Servicios',
        path: '/servicios',
        key: 'services',
        name: 'servicios',
      },
      {
        label: 'FAQ',
        path: '/faq',
        key: 'faq',
        name: 'faq',
      },
    ];
    return {
      isLogged,
      menuItems,
    };
  },
  components: {
    CardCarrito,
  },
  computed: {
    ...mapGetters('signup', ['productOnCart', 'cartTotal', 'cartTotalUrgent']),
    ...mapState('signup', ['cart']),
  },
  methods: {
    ...mapActions('signup', ['getProfileFromStorage', 'getCartFromStorage']),
    cerrarOffcanvas() {
      const offcanvas = document.getElementById('offcanvasRight');
      const offcanvasBody = document.querySelector('.offcanvas-body');
      offcanvas.classList.remove('show');
      offcanvasBody.classList.remove('show');
    },
    fullLogOut() {
      this.$store.dispatch('auth/logout');
      const routeName = this.$router.currentRoute.name;
      if (routeName === 'landing') {
        this.$router.go(0);
      } else {
        this.$router.push('/');
      }
    },
  },
  mounted() {
    this.getProfileFromStorage().then((profileData) => {
      if (profileData) {
        this.isLogged = true;
        this.menuItems = this.menuItems.concat([
          {
            label: 'Mi Cuenta',
            path: '/#/account',
            key: 'account',
          },
          {
            label: 'Mis Órdenes',
            path: '/#/account',
            key: 'orders',
          },
          /*
          {
            label: 'Logout',
            action: 'logout',
            key: 'logout',
          },
          {
            label: 'Cerrar Sesión',
            action: 'logout',
            name: 'logout',
            key: 'logout-2',
          },
          */
        ]);
      } else {
        this.menuItems = this.menuItems.concat([
          {
            label: 'Iniciar Sesión',
            path: '/login',
            key: 'login',
            name: 'login',
          },
        ]);
      }
      console.log('menuItems');
    });
  },
};
</script>
<style lang="scss" scoped>
.boton-verde {
  color: #f4f4f4;
}
.offcanvas {
  width: 35% !important;
}
.btn-bg {
  background-color: #0087a7 !important;
}

.img-logo-menu {
  max-width: 150px;
}
.collapse-btn {
  background-color: #3f51b5 !important;
}
// .collapse-btn:hover {
//   background-color: #fff !important;
// }
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2'  stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
// .navbar-toggler-icon:hover {
//   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2'  stroke-miterlimit='1' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
// }
.bar-menu {
  z-index: 100;
  height: 105px !important;
  background-color: #3f51b5 !important;
}

.nav-item {
  font-size: calc((1 - 1) * 1.2vw + 1rem);
}

.navbarwapi {
  height: auto;
  background-color: #3f51b5 !important;
}

.nav-link {
  color: #f2f2f2 !important;
}
.nav-link .theme--light.v-icon {
  color: #f2f2f2 !important;
}

.nav-link:hover {
  color: #ffffff !important;
}

.light-menu {
  color: #ffffff !important;
}

.light-menu:hover {
  color: #4a516d !important;
}

.icono-social {
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 2px;
  text-decoration: none;
  transition-duration: 0.3s;
  width: 70px;
}

.redondo {
  border-radius: 50%;
}

.icono-social:hover {
  color: #fff;
}

.sombra:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.img-bg {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  height: 80vh !important;
  width: 100%;
}

.barraNavegacion {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.navEsconder {
  transform: translateY(-85px);
  box-shadow: none !important;
}

.shop-bag {
  background-color: #f16032;
  color: #fff;
  height: 50px;
  width: 50px;
  font-size: 25px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.fw-bold {
  font-weight: 700 !important;
  color: #f2f2f2;
}

.qty {
  font-size: 12px;
  color: #f2f2f2;
}
</style>
