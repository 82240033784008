<template>
  <div>
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="col-8 col-md-8 mx-auto">
            <div class="row">
              <div class="col-12 col-md-6" v-for="item in heroProducts" :key="item.id">
                <div class="card text-black card-service">
                  <img :src="item.img_url" class="card-img-top" alt="Apple Computer" />
                  <div class="card-body">
                    <div class="text-center">
                      <h5 class="card-title">{{ item.name }}</h5>
                      <p class="text-muted mb-4">{{ item.service.name }}</p>
                    </div>
                    <div>
                      <div class="d-flex flex-row align-items-center mb-1">
                        <!-- h4 class="mb-1 me-1">$ {{ item.unit_price_normal }}</h4-->
                        <h6 class="d-flex justify-content-between mb-2 w-100">
                          <span class="text-primary">Precio Regular: </span>
                          <span class="text-success">${{ item.unit_price_normal }}</span>
                        </h6>
                      </div>
                      <div class="d-flex flex-row align-items-center mb-1">
                        <h6 class="d-flex justify-content-between mb-2 w-100">
                          <span class="text-primary">Precio Urgente: </span>
                          <span class="text-secondary"
                            >${{ Math.round(item.unit_price_normal * 1.3).toFixed(2) }}</span
                          >
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="d-flex justify-content-center align-items-center pt-2 pb-2">
                      <button
                        type="button"
                        class="btn primary align-items-center pt-2 pb-2"
                        @click="addProductoCar(item)"
                      >
                        <h6 class="mb-0">
                          <i class="mdi mdi-cart icono-blanco"></i> Agregar al Carrito
                        </h6>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="col-12 col-md-4" v-for="item in gridProducts" :key="item.id">
            <div class="card card-services">
              <div class="d-flex justify-content-between p-3">
                <p class="lead mb-0">{{ item.service.name }}</p>
                <div
                  class="bg-info rounded-circle d-flex align-items-center justify-content-center shadow-1-strong"
                  style="width: 42px; height: 42px"
                >
                  <p class="text-white mb-0 small">100%</p>
                </div>
              </div>
              <img :src="item.img_url" class="card-img-top img-normalizada" alt="Laptop" />
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <!-- <p class="small">
                    <a href="#!" class="text-muted">{{ item.office.name }}</a>
                  </p> -->
                </div>
                <div class="d-flex mb-3 flex-column align-items-center">
                  <div class="flex-row justify-content-center">
                    <h5 class="mb-0 text-center">{{ item.name }}</h5>
                  </div>
                  <div class="flex-row mt-3 w-100">
                    <div class="d-flex flex-row align-items-center mb-1">
                      <!-- h4 class="mb-1 me-1">$ {{ item.unit_price_normal }}</h4-->
                      <h6 class="d-flex justify-content-between mb-2 w-100">
                        <span class="text-primary">Precio Regular: </span>
                        <span class="text-success">${{ item.unit_price_normal }}</span>
                      </h6>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-1">
                      <h6 class="d-flex justify-content-between mb-2 w-100">
                        <span class="text-primary">Precio Urgente: </span>
                        <span class="text-secondary"
                          >${{ Math.round(item.unit_price_normal * 1.3).toFixed(2) }}</span
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <!-- div class="d-flex justify-content-between mb-2">
                  <p class="text-muted mb-0">Ranking:</p>
                  <div class="ms-auto text-warning">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="far fa-star"></i>
                  </div>
                </div -->
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-center align-items-center pt-2 pb-2">
                  <button
                    type="button"
                    class="btn primary align-items-center pt-2 pb-2"
                    @click="addProductoCar(item)"
                  >
                    <h6 class="mb-0">
                      <i class="mdi mdi-cart icono-blanco"></i> Agregar al Carrito
                    </h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- eslint-disable max-len -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SeccionProductos',

  data: () => ({
    titlepag: 'Productos',
    discount_alert: {
      message: null,
      type: 'red--text',
    },
    discount_code: null,
    discount: null,
    currentDate: moment().format('YYYY-MM-DD'),
    offices: [],
    paginate: ['products'],
    services: [],
    serviceId: null,
    officeId: null,
    selectServices: [],
    cart: [],
    total: 0,
    productosSuscripcion: [],
    heroProducts: [],
    gridProducts: [],
  }),
  mounted() {
    this.initFunction();
  },
  methods: {
    ...mapActions('signup', ['addProductoCar']),
    // agregar productos a state
    async initFunction() {
      await this.getProductState();
      this.products.map((item) => {
        const currentService = JSON.parse(JSON.stringify(item));
        currentService.service.name = currentService.service.name.replace('.', '');
        if (currentService.service.name.toLowerCase().indexOf('susc') > -1) {
          this.heroProducts.push(currentService);
        } else {
          this.gridProducts.push(currentService);
        }
        return item;
      });
    },
    async getProductState() {
      // this.$store.commit('showLoader');
      try {
        await this.$store.dispatch('signup/getAllProducts');
      } catch (error) {
        console.warn(error);
        this.$store.dispatch('notification/notifyError');
      }
      // this.$store.commit('hideLoader');
    },
  },
  watch: {},
  computed: {
    ...mapState('signup', ['products']),
  },
};
</script>

<style lang="scss" scoped>
.primary {
  background-color: #0283a4 !important;
  color: #fff !important;
}

.card-img-top {
  width: 60%;
  align-self: center;
}
.img-normalizada {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.card-service {
  border: 1px solid #dedede;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.card-services {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.icono-blanco {
  color: #fff;
}
</style>
