<template>
  <div>
    <!--Programar recoleccion-->
    <!--TODO: pedido urgente con calendario de entrega o
                                                definido por el sistema de 48 a 72-->
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12">
        <div class="card card-registration card-registration-2" style="border-radius: 15px">
          <div class="card-body p-0" v-if="cart.length > 0">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="offcanvas-title m-3 mb-0" id="offcanvasRightLabel">Carrito de Compra</h3>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <hr class="my-1" />
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row" v-for="(item, $index) in cart" :key="item.id">
                <div class="col-12">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-md-2 col-lg-2 col-xl-2">
                      <img :src="item.img_url" class="img-fluid rounded-3" :alt="item.name" />
                    </div>
                    <div class="col-md-10 col-lg-10 col-xl-10">
                      <div>
                        <h6 class="text-muted texto-nombre-carrito">{{ item.name }}</h6>
                        <h6 class="text-black mb-0 texto-nombre-carrito">
                          {{ item.service }}
                        </h6>
                      </div>
                      <div class="d-flex flex-row align-items-center mt-2">
                        <div class="d-flex flex-row" style="padding-right: 1rem">
                          <button
                            class="btn btn-link px-1 texto-nombre-total"
                            @click="
                              decrementProductcart(item, $index);
                              enviaraTienda();
                            "
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                          <h5 class="pt-2 texto-nombre-total">
                            {{ item.clothes_quantity }}
                          </h5>
                          <button
                            class="btn btn-link px-1 texto-nombre-total"
                            @click="incrementProductcart(item)"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                        <div class="d-flex">
                          <h6 class="mb-0 texto-nombre-total">$ {{ item.unit_price }}</h6>
                        </div>
                        <div class="text-end texto-nombre-total" style="margin: 0 0 0 auto">
                          <a
                            @click="
                              removeProductFromCart($index);
                              enviaraTienda();
                            "
                            class="text-muted"
                            ><i class="fas fa-times"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0" :onfocus="redireccionar()" v-else>
            <div class="row m-2">
              <div class="col-12">
                <h3>Tu carrito de Wapidu está vacío.</h3>
                <div class="col-lg-12 bg-grey">
                  <div class="pt-5" v-if="pagProductos === true">
                    <h6 class="mb-0">
                      <router-link to="/" tag="button">
                        <i class="fas fa-long-arrow-alt-left me-2"></i>Regresa a la pagina de
                        Inicio</router-link
                      >
                    </h6>
                  </div>
                  <div class="pt-5" v-else>
                    <h6 class="mb-0">
                      <router-link to="/servicios" tag="button">
                        <i class="fas fa-long-arrow-alt-left me-2"></i>Regresa a la pagina de
                        Servicios</router-link
                      >
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'CardCarrito',

  data() {
    return {
      urgent: false,
      pagProductos: false,
    };
  },

  computed: {
    ...mapGetters('signup', ['cartTotal', 'cartTotalUrgent']),
    ...mapState('signup', ['cart']),
  },
  mounted() {},
  methods: {
    ...mapActions('signup', [
      'removeProductFromCart',
      'incrementProductcart',
      'decrementProductcart',
      'addProductoCar',
    ]),
    enviaraTienda() {
      const URLactual = window.location;
      if (URLactual.hash === '#/servicios') {
        this.pagProductos = true;
      }
    },
    // funcion para redireccionar a la pagina de productos si el carrito esta vacio
    redireccionar() {
      const URLactual2 = window.location;
      if (this.cart.length === 0) {
        if (URLactual2.hash !== '#/servicios') {
          // console.log('no es signup');
        } else {
          // alert('Tu carrito de Wapidu está vacío.');
          this.$router.push({ name: 'servicios' });
        }
      }
    },
    urgentOrder() {
      if (this.urgent === true) {
        this.urgent = false;
      } else {
        this.urgent = true;
      }
    },
  },
  created() {
    this.$store.dispatch('signup/getAllProducts');
  },
  watch: {
    pagProductos() {
      this.enviaraTienda();
    },
  },

  components: {},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
.botton-azul {
  background-color: #3f51b5 !important;
  color: #f4f4f4;
}
.hr-60 {
  width: 80%;
}
.botton-negro {
  background-color: #4a516d !important;
  color: #f4f4f4;
}
.botton-negro:hover {
  color: #ffffff;
}
.texto-nombre-carrito {
  font-size: calc(0.7rem + 0.2vw);
}
.texto-nombre-total {
  font-size: calc(0.6rem + 0.3vw);
}
</style>
